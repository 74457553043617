<template lang="pug">
v-card.p-1.w-100
  v-card-title.text-uppercase {{ $t('viewPrice') }} {{ $t(row.type_of_form) }} {{ row.type_document.value }}
  v-card-text
  div
    h3 {{ $t('futurePrice') }}
    TableV2(
      :isViewAdd="false"
      :headers="headers"
      :items="itemsFuture.results"
      :pageCount="itemsFuture.count"
      :currentPage="itemsFuture.current"
      :querySearch="getDocumentsFuture"
      :isLoading="isLoading.future"
      isHiddenTableSearch
      showExpand
      componentEdit="BackOfficeDocumentsPriceOnlineEdit"
      :actions="actions"
      :iconExpandedOpen="iconExpandedOpen"
    )
  div.mt-2
    h3 {{ $t('pastPrice') }}
    TableV2(
      :isViewAdd="false"
      :headers="headers"
      :items="itemsPast.results"
      :pageCount="itemsPast.count"
      :currentPage="itemsPast.current"
      :querySearch="getDocumentsPast"
      :isLoading="isLoading.past"
      isHiddenTableSearch
      showExpand
      componentEdit="BackOfficeDocumentsPriceOnlineEdit"
    )
</template>
<script>
import { hideDetailed, deleteConfirmation } from '@/mixins/main'
import Paginate from '@/components/atoms/Paginate'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BackOfficeDocumentsPriceInfo',
  props: {
    row: { type: Object, default: () => ({}) }
  },
  components: {
    Paginate
  },
  data () {
    return {
      headers: [
        { value: 'type_of_form',
          text: this.$i18n.t('typeDoc'),
          sortable: false
        },
        { value: 'date_start',
          text: this.$i18n.t('dateEffective'),
          sortable: false
        },
        { value: 'full_price',
          text: this.$i18n.t('coming'),
          sortable: false
        },
        { value: 'to_sqc',
          text: this.$i18n.t('toSQC'),
          thClass: 'border-l',
          tdClass: 'border-l',
          sortable: false
        },
        { value: 'to_qd',
          text: this.$i18n.t('toQD'),
          sortable: false
        },
        { value: 'to_td',
          text: this.$i18n.t('toTD'),
          sortable: false
        },
        { value: 'to_sc',
          text: this.$i18n.t('toSC'),
          sortable: false
        },
        { value: 'to_crewing',
          text: this.$i18n.t('toCrewingManager'),
          sortable: false
        },
        { value: 'to_medical',
          text: this.$i18n.t('toMedical'),
          sortable: false
        },
        { value: 'to_cec',
          text: this.$i18n.t('toCEC'),
          sortable: false
        },
        { value: 'to_mrc',
          text: this.$i18n.t('toMRC'),
          sortable: false
        },
        { value: 'to_portal',
          text: this.$i18n.t('toPortal'),
          thClass: 'border-r',
          tdClass: 'border-r',
          sortable: false
        },
        { value: 'sum_to_distribution',
          text: this.$i18n.t('all'),
          sortable: false
        },
        { value: 'profit',
          text: this.$i18n.t('profit')
        },
        { value: 'event',
          text: this.$i18n.t('actions'),
          class: 'mw-0',
          sortable: false
        },
        { text: '', value: 'data-table-expand' }

      ],
      // itemsFuture: {},
      isLoading: {
        past: false,
        future: false
      },
      hideDetailed,
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.delete',
          action: (item) => this.deletePositionPrice(item),
          color: '#F73E07',
          name: 'mdi-trash-can-outline',
          checkAccess: () => true,
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ],
      iconExpandedOpen: {
        name: 'mdi-pen',
        color: 'blue'
      }
    }
  },
  computed: {
    ...mapState({
      itemsFuture: state => state.backoffice.documentsPriceOnlineFuture,
      itemsPast: state => state.backoffice.documentsPriceOnlinePast
    })
  },
  methods: {
    ...mapActions(['getDocumentsPricePastValue', 'getDocumentsPriceFutureValue', 'deleteDocumentPriceOnline']),
    async getDocumentsFuture (filter) {
      this.isLoading.future = true
      let data = {
        filter,
        id: this.row.id
      }
      await this.getDocumentsPriceFutureValue(data)
      this.isLoading.future = false
    },
    async getDocumentsPast (filter) {
      this.isLoading.past = true
      let data = {
        filter,
        id: this.row.id
      }
      await this.getDocumentsPricePastValue(data)
      this.isLoading.past = false
    },
    deletePositionPrice (item) {
      deleteConfirmation(this).then(async (confirmation) => {
        if (confirmation) {
          await this.deleteDocumentPriceOnline({ id: item.id })
          this.$notification.success('priceEtiDeleted')
          this.getDocumentsFuture()
          this.getDocumentsPast()
        }
      })
    }
    /** Get previous price for future value */
    // getFutureValuesInfo (page = null) {
    //   const url = page || `api/v1/back_off/price_for_position/${this.row.id}/future_values/?page_size=20`
    //   this.$api.get(url).then(response => {
    //     if ([200, 201].includes(response.code)) {
    //       response.data.results.map(item => {
    //         item.behavior = {}
    //         item.allowDelete = new Date(item.date_start) > new Date()
    //         // condition for actual value below
    //         item.allowEdit = !((!item.date_end || new Date(item.date_end) >= new Date()) && new Date(item.date_start) <= new Date())
    //       })
    //       this.itemsFuture = response.data
    //     }
    //   })
    // },

    /** Get previous price for position value */
    // getPastValuesInfo (page = null) {
    //   const url = page || `api/v1/back_off/price_for_position/${this.row.item.id}/past_values/?page_size=20`
    //   this.$api.get(url).then(response => {
    //     if ([200, 201].includes(response.code)) {
    //       response.data.results.map(item => {
    //         item.behavior = {}
    //         item.allowDelete = new Date(item.date_start) > new Date()
    //         // condition for actual value below
    //         item.allowEdit = !((!item.date_end || new Date(item.date_end) >= new Date()) && new Date(item.date_start) <= new Date())
    //       })
    //       this.itemsPast = response.data
    //     }
    //   })
    // },

    /** Delete row price for position */
    // deletePositionPrice (row) {
    //   deleteConfirmation(this).then(confirmation => {
    //     if (confirmation) {
    //       this.$api.delete(`api/v1/back_off/price_for_position/${row.item.id}/`).then(response => {
    //         if (response.code === 204) {
    //           this.$notification.success('priceEtiDeleted')
    //           this.getFutureValuesInfo()
    //           this.getPastValuesInfo()
    //         }
    //       })
    //     }
    //   })
    // }
  }
}
</script>
